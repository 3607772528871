<template>
  <AdminToast/>
  <div class="grid">
    <div class="col-12">
      <Panel>
        <template #header>
          <b>Yönetici Bilgileri</b>
        </template>
        <div class="p-fluid grid">
          <div class="field col-12 md:col-12">
            <label for="name">Yönetici Adı</label>
            <InputText id="name" type="text" v-model="dataItem.name"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="surName">Yönetici Soyadı</label>
            <InputText id="surName" type="text" v-model="dataItem.surName"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="email">Yönetici Emai</label>
            <InputText id="email" type="text" v-model="dataItem.email"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="password">Yönetici Şifre</label>
            <InputText id="password" type="text" v-model="dataItem.password"/>
          </div>
          <div class="field col-12 md:col-12">
            <Button label="Kaydet" class="p-button-success p-button-raised p-button-rounded" @click="save"></Button>
          </div>
        </div>
      </Panel>

    </div>
  </div>
</template>

<script>
import AdminToast from "@/components/shared/toast/adminToast";
import {showErrorMessage} from "@/components/shared/toast/toastFunction";
import ManagerUserService from "../../services/managerUserService";

export default {
  _managerUserService: null,
  components: {AdminToast},
  created() {
    this._managerUserService = new ManagerUserService();
  },
  data() {
    return {
      returnField:"",
      dataItem: {
        name: "",
        isActive: "",
      }
    }
  },
  methods: {
    async save() {
      const insertResponse = await this._managerUserService.insertManagerUser(this.dataItem);
      if(insertResponse.isSuccess)
      {
        await this.$router.push({name: "managerUserEdit", params: {managerUserId: insertResponse.data}});
      }
      else {
        showErrorMessage(this, "Yönetici eklemede sorun ile karşılaşıldı");
      }
    }
  }
}
</script>